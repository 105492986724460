import i18n from "i18next"
import { initReactI18next } from "react-i18next"

export const defaultNS = "translation"
export const fallbackNS = "translation"

const fallbackTranslations = {
  en: {
    fallback: "Not found",
  },
  // You can add more fallback translations for other languages here
}

const options = {
  resources: {
    /* English translations */
    en: {
      translation: {
        auth: {
          login: "Login",
          loginTitle: "Log in to Building Permit Hub",
          firstTime: "First time here? You need a BCeID or BC Services Card Account to log in",
          publicLogin: "I am applying for a permit",
          publicLoginDescription: "Create, submit, collaborate, and manage building permit applications",
          localGovLogin: "I represent a local government or First Nation",
          localGovLoginDescription: "Manage and approve building permit applications",
          adminLogin: "Admin login",
          adminAccountAccess: "If you cannot log in with your IDIR, please contact your administrator to gain access.",
          lgPrompt:
            "You must have a BCeID account to use this system. If you don’t have one yet, please register for one based on your use case.",
          publicPrompt:
            "You must have either a BC Services Card Account or BCeID account to use this system. If you don’t have one yet, please register for one based on your use case. <br/><br/> <strong>Please note that BCeID and BC Services Card logins will act as two separate accounts, and you must consistently use the same method for all future logins.</strong>",
          chooseSituation: "Choose the situation that best matches your own to learn more about your login choices.",
          loginHelp: "Having trouble logging in?",
          goToPartners: "Go to our login partners",
          submitterAccordion: "I’m an individual submitter, such as a homeowner or agent",
          lgAccordion: "I’m logging in as a representative of a local government or First Nation ",
          entityAccordion:
            "I’m logging in on behalf of a legal entity, such as a company, partnership, or educational institution ",
          localGov: "Are you a member of local government?",
          chooseLogin: "Choosing the right option",
          matchLogin: "Choose the option that best matches your situation to access this service",
          notLocalGov: "Are you not a member of local government?",
          goToGovLogin: "Go to local government login",
          goToPublicLogin: "Go to general public login",
          bceid: "BCeID",
          bcsc: "BC Services Card Account",
          keycloakinfo: {
            heading: "Which BCeID should I use?",
            useIf: "You can use this option if:",
            bcsc: {
              title: "BC Services Card Account",
              canadianResident: "You're a resident of Canada",
              register: "Set up BC Services Card Account",
            },
            basic: {
              title: "Basic BCeID",
              residentOrNon: "You’re a resident or non-resident of Canada",
              repOrg: "You’re representing a company or organization, but your company doesn’t have a Business BCeID",
              lgReviewManager: "You’re a regional review manager who is also an individual submitter",
              lgJurisdiction: "You’re representing a jurisdiction, but your jurisdiction doesn’t have a Business BCeID",
              register: "Register for Basic BCeID",
            },
            business: {
              title: "Business BCeID",
              entityDescription: "You can use this option if you’re logging in on behalf of a legal entity, such as:",
              lgDescription:
                "Most people representing a local government or jurisdiction will use a Business BCeID to log in",
              company: "Company or partnership or sole proprietorship",
              nonProfit: "Not-for-profit or charitable organization",
              education: "Educational institution like a university or college",
              seeMore: "See more details",
              register: "Register for Business BCeID",
            },
          },
          logout: "Logout",
          submit: "Submit",
          or: "or",
          bceidLogin: "Log in with BCeID",
          bcscLogin: "Log in with BC Services Card Account",
          idirLogin: "Log in with IDIR",
          role: "Role",
          emailLabel: "Email address",
          userFirstNameLabel: "First name",
          userLastNameLabel: "Last name",
          organizationLabel: "Organization",
          organizationHelpText: "(if applicable)",
          register: "Register for account",
          registerButton: "Register",
          registerInstructions:
            "Please fill out the following registration form to create your account. Ensure all information is accurate and up-to-date.",
          certifiedProfessional: "I am a certified professional",
          completeAccountActiviation: "Please confirm your account",
          checkYourEmail:
            "Check your email inbox for a confirmation email to finish activating your new Building Permit Hub account.",
          tokenExpired: "Your session is no longer valid, please login again",
        },
        landing: {
          title: "Building Permit Hub",
          intro:
            "The B.C. government is working with partners across all levels of government and First Nations to standardize and streamline the building permit process and unlock the construction of more homes, faster.",
          easilyUpload: "Easily upload required building permit information",
          bestPractices: "Standardized requirements across participating jurisdictions",
          easyToFollow: "Easy to follow instructions to help you submit a building permit application",
          accessMyPermits: "Access my building permits",
          accessExplanation: "Use your BCeID account to log in or register for the Building Permit Hub.",
          whoForTitle: "Who is this for?",
          whoFor: [
            "I want to build housing",
            "I am an industry professional",
            "I am a homeowner and need a building permit for housing",
            "I am a local government or First Nation and want to accept digital building permit applications",
          ],
          iNeed: "What do I need?",
          whyUseTitle: "Why use this tool?",
          whyUse:
            "The Building Permit Hub helps you submit a building permit application through a streamlined and standardized approach across jurisdictions in B.C. This tool connects you with local government and First Nation information to support the building permit submission process.",
          iNeedLong: "What do I need for a housing building permit?",
          reqsVary:
            "Permit requirements vary by local jurisdiction and depend on the geography of the surrounding location.",
          permitImportance:
            "Building permits are important to ensure your housing is safe, legal and compliant. Building permit requirements vary by local jurisdiction and depend on local servicing, zoning and bylaw requirements (e.g. rural services vs. urban, tree bylaws, etc.).",
          whereTitle: "Where",
          findAuthority: "Find your local building permitting authority.",
          locationOr: "Location or Civic Address",
          withinXRiver: "Within x km of a river",
          withinXForest: "Within x km of a forest",
          withinXProtected: "Within x km of a protected land",
          whatType: "What type of housing are you building?",
          dontSee: "Don't see the type that you're looking for?",
          whenNotNecessaryQ: "When is a permit needed?",
          whenNotNecessaryA:
            "Permits help ensure that construction and major renovations follow local bylaws, the building code and health and safety standards. You will need the required permits before any stage of a project can start. Projects for the interior of your home or minor repairs may not require a permit depending on your local jurisdiction and geography.",
          permitConnect:
            "The B.C government is making housing development projects easier with a new coordinated approach. Visit <1>Permit Connect BC to learn more</1>.",
          expectQ: "What can I expect?",
          expectA:
            "Once you have provided all the information needed for the permits, your local jurisdiction will be notified and will contact you to discuss if more details are needed. This could be because of how the land is used, the rules for building in that area, how much the area can support, the quality of the soil, and/or any environmental concerns.",
          createdQ: "Why was this tool created?",
          createdA:
            "The Building Permit Hub will make it faster and simpler for home builders and developers to send in building permits for new housing online. Local jurisdictions can receive the applications and process them faster too. This tool automatically checks if the application follows the rules in the B.C. Building Code, including the Energy Step Code, to help prevent any delays in the application process.",
          tellUsYourExperience: "Tell us about your experience with the Building Permit Hub",
          addressSelectLabel: "Location or civic address",
          where: "Where",
          findYourAuth: "Find your local building permit authority.",
          localJurisdiction: "Local jurisdiction",
          learnRequirements: "Learn about local requirements",
          cantFind: "Can't find your address?",
          browseList: "Browse list of jurisdictions",
          goTo: "Go to {{ location }}",
          permitApp: "permit app",
          adminPanel: "admin panel",
          enabledCommunitiesDescription: "Communities you can submit Building Permit applications in:",
          moreComingSoon: "(more coming soon)",
          andMore: "...and more",
          additionalContent: {
            left: "See helpful tips from your local jurisdictions to streamline your digital building permit applications",
            mid: "Preview the Small-scale/Multi-unit housing checklist",
            midSub: "(Part 9 BC Building Code)",
            viewTemplate: "View",
            midDownload: "Checklist",
            end: "Accurately fill out your permit application online with ease",
            endButton: "Get started now",
          },
        },
        ui: {
          okay: "Okay",
          filter: "Filter",
          until: "til",
          reorder: "Reorder",
          delete: "Delete",
          confirmDelete: "Confirm delete",
          confirmation: "Are you sure you want to proceed?",
          confirmOverwrite: "Are you sure you want to save and overwrite this item?",
          sureDelete: "Are you sure you want to delete this item?",
          disable: "Disable",
          ok: "OK",
          dismiss: "Dismiss",
          revoke: "Revoke",
          create: "Create",
          verified: "Verified",
          unverified: "Unverified",
          tip: "Tip",
          manage: "Manage",
          export: "Export",
          preview: "Preview",
          back: "Back",
          backToInbox: "Back to inbox",
          backHome: "Back to home",
          yes: "Yes",
          no: "No",
          show: "Show",
          hide: "Hide",
          setup: "Setup",
          search: "Search",
          loading: "Loading...",
          invalidInput: "Invalid input",
          invalidEmail: "Invalid email",
          selectPlaceholder: "Select",
          selectApplicable: "Select applicable:",
          clickHere: "Click here",
          clickToEdit: "Click to edit",
          clickToShow: "Click to show",
          feedbackLink: "Tell us what you think",
          sortBy: "Sort by",
          resume: "Resume",
          cancel: "Cancel",
          remove: "Remove",
          save: "Save changes",
          onlySave: "Save",
          done: "Done",
          view: "View",
          totalItems: "Total items",
          doLater: "Do this later",
          add: "Add",
          edit: "Edit",
          optional: "(optional)",
          archive: "Remove and archive",
          restore: "Restore",
          seeArchivedButton: "See archived",
          seeUnarchivedButton: "See unarchived",
          never: "never",
          submit: "Submit",
          select: "Select",
          selected: "selected",
          notAvailable: "Not available yet",
          notApplicable: "Not applicable",
          isRequired: "{{field}} is required",
          notSupported: "Not supported",
          use: "Use",
          publish: "Publish",
          neverMind: "Never mind",
          open: "Open",
          download: "Download",
          expandAll: "Expand all",
          collapseAll: "Collapse all",
          toTop: "Go to top",
          confirm: "Confirm",
          modifiedBy: "Modified by",
          searchAddresses: "Search addresses",
          typeToSearch: "Begin typing to search",
          close: "Close",
          asc: "Ascending",
          desc: "Descending",
          returnHome: "Return to home",
          copied: "Copied!",
          failedToCopy: "Something went wrong while trying to copy",
          reset: "Reset",
          autofill: "Autofill",
          help: "Help",
          pleaseSelect: "Please select",
          checked: "Checked",
          unchecked: "Unhecked",
          showAdvanced: "Show advanced",
          hideAdvanced: "Hide advanced",
          emailPlaceholder: "Please enter an email",
          urlPlaceholder: "https://",
          showOlder: "Show older",
          seeMore: "See more",
          go: "Go",
          all: "All",
          and: "and",
          or: "or",
          actionRequired: "Action required",
          resetFilters: "Reset filters",
          customize: "Customize",
          acknowledgeAndDismiss: "Acknowledge and dismiss",
          markedForRemoval: 'Click "Save changes" to confirm removal',
          proceed: "Proceed",
          copy: "Copy",
          copyNoun: "Copy",
          options: "Options",
          na: "N/A",
          share: "Share",
          unassigned: "Unassigned",
          unassign: "Unassign",
          seeEarlyAccessButton: "See Early Access",
          seeLiveButton: "See Live",
          invite: "Invite",
          public: "Public",
        },
        notification: {
          title: "Notifications",
          nUnread: "{{ n }} new",
          noUnread: "No unread notifications",
        },
        eula: {
          title: "End-User License Agreement",
          accept: "Accept agreement",
        },
        userEulas: {
          title: "End user license agreement",
          viewAgreement: "View agreement",
          view: "View",
          actionRequired: "Action required",
          acceptedOn: "You accepted this agreement on {{date}} at {{time}}.",
          pastAgreementsModal: {
            title: "Past end user license agreements",
            triggerButton: "See past",
            acceptedOn: "Accepted on {{ timestamp }}",
          },
        },
        contact: {
          create: "New contact",
          edit: "Edit contact",
          createButton: "Save to my contacts",
          updateButton: "Update contact",
          confirmDeleteTitle: "Are you sure you want to delete this contact?",
          confirmDeleteBody: "This will not remove information from the form.",
          fields: {
            firstName: "First name",
            lastName: "Last name",
            title: "Role/Position",
            department: "Department",
            email: "Email",
            phone: "Phone no.",
            extension: "Extension",
            cell: "Mobile no.",
            organization: "Organization",
            address: "Address",
            businessName: "Business name",
            professionalAssociation: "Professional association",
            professionalNumber: "Professional number",
          },
        },
        jurisdiction: {
          edit: {
            displayDescriptionLabel: "Jurisdiction description (public)",
            title: "Rename jurisdiction",
            addDescription: "Click to add a description",
            displayChecklistLabel: "Permit application checklist (public)",
            addChecklist: "Click to add a permit application checklist",
            displayLookOutLabel: '"Look Out" Section (public)',
            addLookOut: 'Click to add a "Look Out" section',
            displayContactSummaryLabel: "Contact Summary Section (public)",
            addContactSummary: "Click to add a Contact Summary section",
            firstContact: "The first contact will be displayed upon successful permit application submission.",
            clickToEditContacts: "Click to edit contacts",
            clickToShowContacts: "Click to show contacts as they will be seen",
            clickToEditMap: "Click to edit map",
            clickToSeeMap: "Click to see map",
            clickToShowMap: "Click to show map as it will be seen",
            editMapStart: "To display a map of your jurisdiction on the 'About' page, please follow these steps:",
            editMapSteps: [
              "Identify the central point of your jurisdiction on Google Maps.",
              "Right-click on the desired central location to open a context menu.",
              "Select the option that displays the coordinates to copy them.",
              "Individually paste these coordinates into the fields below",
              "Adjust the zoom level below to your preference.",
              "Remember to click 'Save' at the bottom of the page to apply your changes.",
            ],
            editMapEnd:
              "This process ensures the map accurately represents the center of your jurisdiction and is displayed with the appropriate level of detail.",
            stepCode: {
              title: "Step code requirements",
              description:
                "Below are the step code requirements for each permit application type. <1>What does each step code level mean?<2></2></1>",
              permitType: "Permit type",
              energyStepRequired: "Energy step code required",
              zeroCarbonStepRequired: "Zero carbon step required",
            },
            updateButton: "Update jurisdiction",
          },
          new: {
            title: "Create new jurisdiction",
            createButton: "Create jurisdiction",
            nameLabel: "Name of local jurisdiction",
            postalAddressLabel: "Postal address",
            nextStep: "The next step is to invite users",
            useCustom: "Use a custom locality type",
          },
          index: {
            title: "Jurisdictions",
            description: "Below is a list of all jurisdictions in the system",
            createButton: "Create new jurisdiction",
            tableHeading: "Local governments",
            users: "Users",
            externalApiKeys: "API settings",
            exportTemplates: "Export templates",
            about: "About",
            rename: "Rename",
          },
          fields: {
            reverseQualifiedName: "Name",
            reviewManagersSize: "Managers",
            reviewersSize: "Reviewers",
            localityType: "Locality type",
            permitApplicationsSize: "Applications received",
            templatesUsed: "Templates used",
            mapPosition: "Map position",
            regionalDistrictName: "Regional district",
            submissionInboxSetUp: "Accepting submissions",
          },
          lat: "Latitude",
          lng: "Longitude",
          title: "Local housing permits",
          notEnabled: "Permit application submissions are temporarily unavailable for this jurisdiction.",
          checklist: "Checklist",
          lookOut: "Things to look out for",
          startApplication: "Start a permit application",
          contactInfo: "Contact information",
          didNotFind: "I didn't find what I was looking for",
          viewAs: "View as",
          name: "Name",
          managers: "Managers",
          reviewers: "Reviewers",
          applicationsReceived: "Applications received",
          templatesUsed: "Templates used",
          search: "Search jurisdictions",
          view: "View Jurisdiction",
        },
        permitCollaboration: {
          status: "Status",
          blockStatus: {
            draft: "Draft",
            in_progress: "In progress",
            ready: "Ready",
          },
          sidebar: {
            triggerButton: "Collaborators ({{count}})",
            title: "Collaborators",
            description: {
              submission:
                "Collaborators can be invited to work on the different areas of the permit application form. Only the author can manage collaborators.",
              review: "Collaborators can be invited to work on the different areas of the permit application form.",
            },
            howItWorksTitle: "How it works",
            howItWorksDescription: {
              submission:
                "The collaboration feature allows the author of a permit application to invite collaborators by email. Invited collaborators must register for an account through BCeID if they do not have one already.<1/><1/> Collaborators can view and contribute only to the requirement blocks they are assigned. Notifications are sent to the collaborators when they are assigned to a requirement block, and their avatars are displayed next to the blocks they are assigned to.<1/><1/> Additionally, the designated submitter has access to the entire application and can submit it on behalf of the author. The author can manage and remove collaborators as needed.",
              review:
                "The collaboration feature allows reviewers of a submitted permit application to invite additional reviewers, provided they are already part of the jurisdiction.<1/><1/> Reviewers can view and contribute to the assigned requirement blocks and update the status of each block. Notifications are sent when reviewers are assigned to a requirement block or when the status changes to “Ready”. Avatars are displayed next to the blocks to indicate who is assigned. Status updates are visible only to reviewers within the local jurisdiction.<1/><1/> The designated reviewer serves as the primary contact internally at the local jurisdiction, responsible for overseeing the submitted permit application.",
            },
            designatedSubmitters: "Designated submitter(s)",
            designatedReviewers: "Designated reviewer(s)",
            authorCanSubmitWithOrganization:
              "Author of this application is also allowed to submit: <1>{{author}}</1> ({{organization}}).",
            authorCanSubmit: "Author of this application is also allowed to submit: <1>{{author}}</1>.",
            assignees: "Assignee(s)",
            assigneeHelperText:
              "To assign collaborators, look for the grey header at the top of each requirement block and click “Assign”.",
            noDesignatedSubmitters: "None assigned",
            assignedTo: "Assigned to",
            assigneeEmail: "Email:",
            removeCollaboratorModal: {
              triggerButton: "Remove collaborator",
              title: "Are you sure you want to remove this collaborator?",
              body: "This action cannot be undone.",
            },
          },
          popover: {
            triggerButton: "Assign ({{count}})",
            designatedSubmitterChangeButton: "Change",
            collaborations: {
              title: "Collaborators",
              assignButton: "Assign",
              unassignButton: "Unassign",
              unconfirmed: "Unconfirmed.",
              resendInvite: "Resend invite?",
              inEligibleForReInvite: "Ineligible for re-invite.",
            },
            assignment: {
              title: "Assign collaborator",
              noneAssigned: "None assigned",
              inviteWarning: {
                title: "Assign a collaborator?",
                body: "When you are inviting collaborators to participate in a building permit application through our system, it’s important to understand the specific roles and permissions involved. Only the person who creates the permit application, referred to as the “author,” has the ability to invite collaborators. These collaborators are then only able to access specific sections of the application—those that they are specifically assigned to. Importantly, while the author and one person they designate (Designated Submitter) can submit the completed application, all collaborators must have a verified Building Permit HUB account using BCeID to ensure security. Additionally, the author retains the exclusive right to remove or reassign collaborators. This ensures that control over the application remains centralized while still allowing for collaborative input where necessary.",
              },
              newContactButton: "New contact",
              noResultsText: {
                invitable: "No results found. Invite new collaborators by clicking the button above.",
                default: "No results found.",
              },

              unassiggn: "Unselect",
            },
            collaboratorInvite: {
              title: "New contact",
              inviteButton: "Invite collaborator",
            },
          },
        },
        permitApplication: {
          noneFound: "No permits yet",
          noneFoundExplanation:
            "Missing permits? You might have used a login option different from the one you used to create the permits. Log out and try logging back in with the BCeID or BC Services Card Account you used to create them.",
          submissionBlockModal: {
            title: "Trying to submit this application?",
            description:
              "Only the designated submitter or the author can submit this permit application. Please contact that person to continue.",
            designatedSubmitter: "Designated submitter",
            author: "Author",
          },
          browserSearch: {
            prompt: "Find specific form fields by using your web browser search: press keyboard shortcut",
            windows: "Windows: <1>Ctrl</1> + <1>F</1>",
            mac: "Mac: <1>Cmd</1> + <1>F</1> ",
          },
          updateToNewVersion: "Update my application",
          reviewOutdatedSubmissionLink: "View draft applications",
          configureStepCodePageLink: "Configure Step Code(s)",
          reviewUpdatedEditLink: "View template",
          reviewOutdatedTitle: "Filters applied to show applications that are outdated",
          reviewOutdatedMessage: "Filters have been applied. Please review and acknowledge the actions required below.",
          reviewCustomizedSubmissionLink: "View draft applications",
          reviewCustomizedTitle: "Filters applied to show applications that have new customizations added",
          reviewCustomizedMessage: "Filters have been applied. Please review the draft applications below.",
          newVersionPublished: "New version of template has been published - please review changes",
          card: {
            collaborateButton: "Collaborate",
            collaborationCalloutDraft:
              "<1>{{authorName}} has assigned you to this permit.</1> Collaborate on this permit application.",
            collaborationCalloutSubmitted: "<1>{{authorName}} assigned you to this permit.</1>",
          },
          referenceNumber: "Reference #",
          pdf: {
            for: "Permit application for",
            id: "Application ID",
            submissionDate: "Submission date",
            applicant: "Applicant",
            jurisdiction: "Jurisdiction",
            page: "Page {{pageNumber}} / {{totalPages}}",
            fileNotAdded: "Not uploaded",
            permitType: "Permit type",
            contactInfo: "Contact information",
            applicantInfo: "Applicant contact details",
          },
          usePid: "Use '{{ inputValue }}' as PID",
          indexTitle: "My active permits",
          start: "Start a permit application",
          drafts: "Draft Permits",
          startedOn: "Started on",
          lastUpdated: "Last updated",
          viewedOn: "Viewed on",
          seeBestPractices_CTA: "See best practices",
          seeBestPractices_link:
            "https://www2.gov.bc.ca/gov/content/housing-tenancy/building-or-renovating/permits/building-permit-hub#practices",
          searchKnowledge_CTA: "Ask a question",
          searchKnowledge_link:
            "https://www2.gov.bc.ca/gov/content/housing-tenancy/building-or-renovating/permits/building-permit-hub-search",
          pidLabel: "Parcel Identification (PID) No.",
          addressLabel: "Address",
          viewed: "Viewed",
          notViewed: "New",
          newlyRevised: "Newly revised",
          status: {
            new_draft: "Draft",
            newly_submitted: "Submitted",
            resubmitted: "Resubmitted",
            revisions_requested: "Revisions Requested",
            ephemeral: "Preview",
          },
          statusGroup: {
            draft: "Draft permits",
            submitted: "Submitted permits",
          },
          columns: {
            number: "Application #",
            reference_number: "Reference #",
            permit_classification: "Types",
            submitter: "Submitter",
            submitted_at: "Submitted at",
            viewed_at: "Viewed at",
            status: "Status",
          },
          submissionInbox: {
            contactInviteWarning:
              "Please have a Review Manager setup the Submissions Inbox for all permit types to allow submissions to be received.",
            title: "Submissions inbox",
            tableHeading: "Permit applications",
            submissionsSentTo:
              "A copy of all submitted applications are also sent to one or more email addresses configured by the review manager.",
          },
          fields: {
            number: "Application #",
            pin: "PIN",
            jurisdiction: "Jurisdiction",
          },
          filterMenu: {
            collaborating: "Collaborating",
          },
          new: {
            locationHeading: "Location for permit",
            submitToOwn:
              "Make sure you are submitting to a jurisdiction that you have inbox access to so that you can see it.",
            sandboxIdHeading: "Submit into Sandbox",
            onlyHavePin: "I don't have a PID or address",
            dontHavePin: "Hide",
            selectSandboxLabel: "Select a sandbox to submit into",
            firstNationsTitle: "First Nations",
            permitTypeHeading: "Permit type",
            workTypeHeading: "Work type",
            forFirstNations: "Is this permit application on <1>First Nation Registered Land</1>?",
            applicationDisclaimerInstruction:
              "Before you submit a building permit application, please ensure your proposed building siting and design complies with:",
            applicationDisclaimers: [
              { text: "provincial building code", href: "https://www.bccodes.ca/building-code.html" },
              {
                text: "local zoning bylaw",
                href: "https://www2.gov.bc.ca/gov/content/governments/local-governments/planning-land-use/land-use-regulation/zoning-bylaws",
              },
              {
                text: "official community plan regulations",
                href: "https://www2.gov.bc.ca/gov/content/governments/local-governments/planning-land-use/local-government-planning/official-community-plans",
              },
              {
                text: "applicable development permit areas",
                href: "https://www2.gov.bc.ca/gov/content/governments/local-governments/planning-land-use/land-use-regulation/development-permit-areas",
              },
              {
                text: "variances outlined in a local bylaw",
                href: "https://www2.gov.bc.ca/gov/content/governments/local-governments/planning-land-use/land-use-regulation/zoning-bylaws/board-of-variance",
              },
            ],
            applicationDisclaimerMoreInfo:
              "For more information and specific building drawing requirements and checklists, contact your local jurisdiction.",
            applicationDisclaimerMoreInfo_CTA: "Find your local jurisdiction",
            applicationDisclaimerMoreInfo_Link:
              "https://www2.gov.bc.ca/gov/content/governments/local-governments/facts-framework/local-government-maps",
            submitted: "Your application has been submitted!",
            hearBack: "Need Help?",
            contactInstruction:
              "You can log into the Building Permit Hub at any time to view a history of the applications you have submitted. Please contact the {{ jurisdictionName }} for questions related to your application.",
            whatsNextTitle: "What's next?",
            ready: "Ready to submit this application?",
            bySubmitting: "By submitting this application",
            confirmation:
              "You confirm that the information you provided was completed to the best of your knowledge and ability",
            yourReference: "For reference, your BC Building Permit Hub Application # is {{ number }}",
            noContactsAvailable:
              "This jurisdiction is not ready to accept applications through this tool yet. Please check with your local jurisdiction.",
            whatsNext:
              "Upon receipt by the local jurisdiction, you will be notified via email or phone of any updates to your application's status or if additional documentation is required.",
            emailed:
              "A confirmation email has also been sent to the applicant and the {{ jurisdictionName }} building permit office",
            pinRequired: "PID not found or unavailable. Please optionally select a PIN and jurisdiction below:",
            pinVerified: "PIN is verified.",
            pinUnableToVerify: "Unable to verify PIN, please confirm and proceed as applicable.",
            needToKnow: "What you need to know",
            disclaimer1:
              "You can use this website to submit a permit application for your building project. This website checks if your application meets some codes, but approval isn’t automatic.",
            disclaimer2:
              "After you’ve submitted your application, local officials will review it. They may ask you to fix issues or show that your application meets requirements before approving it.",
          },
          edit: {
            saveDraft: "Save and finish later",
            submit: "Submit application",
            permit: "Permit",
            fullAddress: "Full address",
            pidPin: "PID / PIN",
            clickToWriteNickname: "Click to write a nickname",
          },
          show: {
            wasSubmitted: "Application was submitted on {{ date }} to {{ jurisdictionName }}",
            submittingTo: {
              title: "You're applying to the {{ jurisdictionName }}",
              description:
                "Please verify that this building permit is in compliance with <1>local zoning bylaws</1> for this specific location.",
            },
            versionDiffContactWarning:
              "A new version of the permit is available. Please ask author or designated submitter to review and acknowledge changes to proceed.",
            contactsSummary: "Contacts summary",
            inboxDisabledTitle: "Inbox disabled",
            inboxDisabled:
              "Submissions for this local jurisdictions are currently disabled. You will be able to make edits to this permit application but will not be able to submit until this jurisdiction's is accepting submissions again.",
            downloadApplication: "Download application",
            fetchingMissingPdf: "Fetching {{missingPdf}}...",
            missingPdfLabels: {
              permitApplication: "permit application pdf",
              stepCode: "step code checklist pdf",
            },
            contactSummaryHeading: "List of all contacts on this application",
            downloadHeading: "Download application",
            downloadPrompt: "Choose specific files or entire package:",
            downloadZip: "Download attached files",
            downloadForm: "Download application form",
            downloadStepCode: "Download step code checklist",
            requestRevisions: "Request revisions",
            viewRevisionRequests: "View revision requests",
            requestingRevisions: "Requesting revisions",
            requestedRevisions: "Requested revisions",
            pleaseFix: "Please fix the requested revisions and re-submit your application",
            onlySpecified:
              "Only the the specified fields highlighted below (in yellow) and their corresponding blocks can be edited",
            showList: "Show list",
            hideList: "Hide list",
            backToInbox: "Back to inbox",
            locateRevisions: "Locate each requested revision by clicking the links below.",
            revisionsWereRequested: "Revisions to this application were requested on {{date}}",
            clickQuestion: "Click on the question(s) or requirement(s) you want the submitter to revise.",
            revision: {
              newRevision: "New revision",
              pastRequests: "Past requests",
              reason: "Reason",
              reasonCode: "Reason code",
              revisionRequest: "Revision request",
              reasonFor: "Reason for revision",
              comment: "Comment (Optional)",
              useButton: "Use this reason",
              maxCharacters: "Maximum 350 characters",
              send: "Send to submitter",
              confirmHeader: "Send this list of revisions to the submitter?",
              originallySubmitted: "Previously submitted",
              confirmMessage: "The submitter will receive a notification end email about the changes you've requested.",
            },
          },
        },
        sandbox: {
          formLabel: "Sandbox",
          live: "Live (None)",
          disabledFor: "Disabled for sandbox",
          inMode: "You're in testing mode:",
          switch: {
            label: "Sandbox mode",
            title: "Enter testing sandbox mode?",
            description: "While in this mode, you will be able to:",
            descriptionList: [
              "make changes to a permit template without affecting what is published live.",
              "publish permit templates for testing it out as how a submitter would see it",
              "if you made any changes on a permit, you may choose to copy those over to publish live.",
            ],
            choicesAvailable: "Choices available",
            liveDescription: "<strong>Live</strong>: No sandbox. Submit directly to the live inbox.",
            publishedDescription:
              "<strong>Published sandbox:</strong> Sandbox that emulates the live mode by letting you test edits on published permit templates.",
            scheduledDescription:
              "<strong>Scheduled sandbox:</strong> Sandbox that lets you interact with scheduled permit templates.",
            continue: "Continue to sandbox",
            superAdminAvailable: "Super Admin feature available!",
            testingPurposes: "For testing purposes, you may choose which permit applications to test.",
          },
        },
        earlyAccessRequirementsLibrary: {
          index: {
            title: "Early access requirements library",
            description:
              "This displays all requirement blocks whose visibility has been set to 'preview only'. These blocks may not be used in any live drafts.",
            tableHeading: "Early access requirement blocks",
          },
        },
        requirementsLibrary: {
          addAnother: "Add",
          addAnotherPerson: "Add another person",
          elective: "Elective",
          hasElective: "Has elective(s)",
          forFirstNations: "Intended for First Nations",
          hasConditionalLogic: "Has conditional logic",
          hasAutomatedCompliance: "Has automated compliance",
          inputNotSupported: "Input type not yet supported",
          associationsInfo: "Sections, tags, etc...",
          copyToEarlyAccess: {
            title: "Copy to early access",
            body: "Do you want to deuplicate this into an early access requirement block? <br /> <br /> <strong>Replace and duplicate with early access:</strong>",
            replaceButton: "Replace requirement block",
          },

          index: {
            title: "Requirements library",
            description: "List of all Requirement Blocks in the system that can be used inside Templates.",
            tableHeading: "Requirement blocks",
            createButton: "Create new requirement block",
          },
          fieldsDrawer: {
            formFields: "Form fields",
            useButton: "Use",
            dummyOption: "Option",
          },
          visibilityDescriptions: {
            any: "No restrictions on visibility.",
            live: "Can only be used in live templates only. Does not affect published templates.",
            earlyAccess:
              "Available to be used in early access preview templates only. Does not affect published templates",
          },
          visibility: {
            any: "Any",
            live: "Preview omitted",
            earlyAccess: "Preview only",
          },
          modals: {
            archived: "Archived",
            unlabeled: "Unlabeled",
            defaultContactLabel: "Contact",
            canAddMultipleContacts: "Submitter can add multiple contacts",
            addLabel: "Add label",
            displayDescriptionLabel: "Instruction/Description (public)",
            addDescriptionTrigger: "Add instructions/description for this block",
            visibilityLabel: "Visibility",
            cantEditHere: "Not currently editable here",
            changeVisibility: {
              fromEarlyAccessTitle: "Are you sure you want to promote this?",
              fromLiveTitle: "Are you sure you want to promote this?",

              confirmChangeBody1:
                "This is only possible if the block only currently exists in the correct corresponding in-progress template drafts and previews. Once changed, it will be in the corresponding library only",
              confirmChangeBody2:
                "Make sure you are ready to promote/demote this, any other previews or templates using this specific block will also reflect this change.",
              listItem1: "<strong>All</strong>: The requirement block may exist in any preview or template",
              listItem2:
                "<strong>Preview omitted</strong>: The requirement block may not exist in previews, and only in templates",
              listItem3:
                "<strong>Preview only</strong>: The requirement block may not exist in templates, and only in previews",
            },
            create: {
              triggerButton: "Create new requirement block",
              title: "New requirement block",
            },
            edit: {
              title: "Edit requirement block",
              options: "Options",
              copy: "Copy this block",
              visibilityTooltip:
                "Visibility determines if the block is restricted to live, early-access previews, or both",
              removeConfirmationModal: {
                title: "Confirm you want to archive this requirement block.",
                body: "Archiving this requirement blocks will remove it from all draft templates. This action cannot be undone.",
              },
            },
            clickToWriteDisplayName: "Click to write display name",
            clickToWriteDescription: "Click to write description",
            clickToWriteNickname: "Click to write nickname",
            blockSetupTitle: "Block setup",
            internalUse: "For internal use only",
            configureFields: "Configure the form fields below that submitters will see:",
            noFormFieldsAdded: "No form fields have been added yet, start by clicking the Add button.",
            defaultRequirementLabel: "Label",
            addHelpText: "Add help text",
            addHelpTextLabel: "Help text",
            editHelpTextLabel: "Edit help text",
            helpTextPlaceHolder: "Help text",
            optionalForSubmitters: "This field is optional for submitters",
            isAnElectiveField: "This is an elective field for local jurisdictions",
            optionsMenu: {
              triggerButton: "Options",
              remove: "Remove",
              conditionalLogic: "Conditional logic",
              automatedCompliance: "Automated compliance",
              dataValidation: "Data validation",
            },
            conditionalSetup: {
              ge: "Greater or equal to",
              le: "Less than or equal to",
              gt: "Greater than",
              lt: "Less than",
              ne: "Not equal to",
              when: "For question:",
              eq: "Equals",
              satisfies: "Submitter responds with:",
              then: "Then...",
              show: "Show this field",
              hide: "Hide this field",
            },
            computedComplianceSetup: {
              module: "Module",
              valueExtractionField: "Value extraction field",
              optionsMapGrid: {
                title: "Options mapper",
                externalOption: "External option",
                requirementOption: "Requirement option",
              },
            },
            addOptionButton: "Add another option",
            templateEditWarning:
              "Any changes made here will be reflected in all unsaved preview drafts that use this requirement block.",
            previewEditWarning:
              "Any changes made here will be reflected in all in-progress template drafts that use this requirement block.",
            templates: "templates",
            previews: "previews",
            stepCodeDependencies: {
              energyStepCodeMethod: {
                tool: "Utilizing the digital step code tool",
                file: "By file upload",
                label: "Which method do you want to do use for the energy step code",
              },
              energyStepCodeToolPart9: {
                label:
                  "Please use this tool to do your fill in your step code details and it will populate onto the application.",
              },
              energyStepCodeReportFile: {
                label: "BC Energy Step Code Compliance Report",
              },
              energyStepCodeH2000File: {
                label: "Pre construction Hot2000 model details, Hot2000 report",
              },
            },
          },
          fields: {
            name: "Name",
            firstNations: "First Nations",
            description: "Description",
            associations: "Associations",
            formFields: "Form fields",
            updatedAt: "Updated at",
            requirementSku: "Requirement SKU",
          },
          configurationsColumn: "Configurations",
          fieldDescriptions: {
            description: "Provide some context for admins and managers for this fieldset.",
            associations: "Assign a tag to help organize and find this requirement block easier.",
            requirementSku: "Generated unique identifier",
          },
          requirementTypeLabels: {
            text: "Short text",
            checkbox: "Checkbox",
            address: "Address",
            bcaddress: "BC address",
            date: "Date",
            number: "Number",
            textArea: "Text area",
            radio: "Radio options",
            multiOptionSelect: "Multi-select checkboxes",
            select: "Single select dropdown",
            signature: "Signature",
            generalContact: "General contact",
            professionalContact: "Professional contact",
            file: "File upload",
            phone: "Phone",
            email: "E-mail",
            energyStepCode: "Energy Step Code",
            stepCodePackageFile: "Design package file for energy step code",
            pidInfo: "Pid Info",
          },
          contactFieldItemLabels: {
            firstName: "First name",
            lastName: "Last name",
            email: "Email",
            title: "Role/Position",
            phone: "Phone",
            address: "Address",
            organization: "Organization",
            businessName: "Business name",
            businessLicense: "Business license",
            professionalAssociation: "Professional association/organization",
            professionalNumber: "Professional number",
          },
          descriptionMaxLength: "(Max length: 250 characters)",
          unitLabels: {
            option: {
              noUnit: "(no unit)",
              mm: "mm - millimeters",
              cm: "cm - centimeters",
              m: "m - meters",
              in: "in - inches",
              ft: "ft - feet",
              mi: "mi - miles",
              sqm: "sqm - sq meters",
              sqft: "sqft - sq feet",
              cad: "$ - CAD",
            },
            display: {
              noUnit: "(no unit)",
              mm: "millimeters (mm)",
              cm: "centimeters (cm)",
              m: "meters (m)",
              in: "inches (in)",
              ft: "feet (ft)",
              mi: "miles (mi)",
              sqm: "sq meters (sqm)",
              sqft: "sq feet (sqft)",
              cad: "$ (CAD)",
            },
          },
        },
        formComponents: {
          energyStepCode: {
            edit: "View and edit compliance report",
            warningFileOutOfDate:
              "Plan file updated may be out of date as it differs from when the step code was created.",
          },
        },
        stepCode: {
          title: "Step code auto-compliance tool",
          subTitle:
            "You can use this tool to generate your BC Energy Step Code Compliance Report. Approval is not automatic. After you've submitted your application, local officials will review it. They may ask you to fix issues or show that your application meets requirements before approving it.",
          checklistGuide: "See checklist guide",
          helpLink:
            "https://www2.gov.bc.ca/gov/content/housing-tenancy/building-or-renovating/permits/building-permit-hub/29065",
          helpLinkText: "What does each step code level mean?",
          saveAndGoBack: "Save and go back",
          markAsComplete: "Mark as complete",
          back: "Back to permit application",
          complete: "Complete",
          restart: {
            trigger: "Restart",
            confirm: {
              title: "Are you sure?",
              body: "Restarting will clear all checklist fields",
            },
          },
          info: {
            title: "BC Energy Compliance Reports",
            energy: "Energy Step Code",
            zeroCarbon: "Zero Carbon Step Code",
            performancePaths: {
              title: "For Performance Paths:",
              ers: "9.36.6. BC Energy Step Code ERS",
              necb: "9.36.6. BC Energy Step Code NECB",
              passive: "9.36.6. Passive House",
              stepCode: "9.36.5 BC Energy Step Code",
            },
            more: {
              prompt: "More details can be found at ",
              link: "energystepcode.ca",
            },
          },
          drawingsWarning: {
            title: "Before you start",
            description:
              "Please make sure you have finished uploading all your finalized drawings before generating the report. If you make changes to your uploaded drawings after import, you will need to go through this Step Code Auto-Compliance Tool again.",
          },
          import: {
            title: "Import",
            selectFile: "Select .h2k file",
            compliancePath: {
              label: "BC Building Code Performance Compliance Path:",
              select: "Select",
              options: {
                step_code_ers: "9.36.6 BC Energy Step Code ERS",
                step_code_necb: "9.36.6 BC Energy Step Code NECB",
                passive_house: "9.36.6 Passive House",
                step_code: "9.36.5 BC Energy Step Code",
              },
            },
            districtEnergyEF: "District energy EF",
            districtEnergyConsumption: "District energy consumption",
            otherGhgEf: "Other GHG EF",
            otherGhgConsumption: "Other GHG consumption",
            create: "Create",
            addData: "Add data",
          },
          index: {
            heading: "Step Codes",
            newStepCode: "New step code",
          },
        },
        stepCodeChecklist: {
          pdf: {
            for: "Step code pre-construction checklist for",
          },
          edit: {
            heading: "BC Step Code Compliance Checklist - Part 9 Buildings",
            notice: "Relevant data fields below has been filled in for you by Auto-Compliance.",
            energyStepNotMet:
              "Minimum energy step was not met. Please see <1>F: 9.36.6 Energy Step Code Compliance</1> for details.",
            zeroCarbonStepNotMet:
              "Minimum zero carbon step was not met. Please see <1>G: Zero Carbon Step Code Compliance</1> for details.",
            projectInfo: {
              stages: {
                pre_construction: "Pre Construction",
                mid_construction: "Mid Construction",
                as_built: "As Built",
              },
              heading: "A: Project Information",
              permitNum: "Building permit #",
              builder: "Builder",
              address: "Project address",
              postalCode: "Postal code",
              jurisdiction: "Municipality / District",
              pid: "PID or legal description",
              buildingType: {
                label: "Building type",
                placeholder: "Select",
                options: {
                  laneway: "Laneway house",
                  single_detached: "Single detached",
                  double_detached: "Double/Semi-detached (non-MURB)",
                  row: "Row house (non-MURB)",
                  multi_plex: "Multi-plex (non-MURB)",
                  single_detached_with_suite: "Single detatched w/ secondary suite",
                  low_rise_murb: "Low-rise MURB",
                  stacked_duplex: "Stacked duplex (MURB)",
                  triplex: "Triplex (MURB)",
                  retail: "Retail",
                  other: "Other",
                },
              },
              select: "Select",
              dwellingUnits: "Number of dwelling units",
            },
            codeComplianceSummary: {
              heading: "B: Code Compliance Summary",
              required: "Required",
              notMet: "not achieved",
              stepRequirement: {
                heading: "Step Requirements",
                label: "Please select the energy and zero carbon step combination you are trying to achieve:",
                helpText:
                  "Please reference the <1>step code tool page<2></2></1> for more details and helpful information.",
              },
              compliancePath: {
                label: "BC Building Code Performance Compliance Path:",
                options: {
                  step_code_ers: "9.36.6 BC Energy Step Code ERS",
                  step_code_necb: "9.36.6 BC Energy Step Code NECB",
                  passive_house: "9.36.6 Passive House",
                  step_code: "9.36.5 BC Energy Step Code",
                },
              },
              energyStepRequired: "Energy step code required",
              zeroCarbonStepRequired: "Zero carbon step required",
              notRequired: "Not Required",
              energyStepCode: {
                heading: "Energy Step Code",
                stepRequired: "Step required",
                stepProposed: "Proposed step achieved",
                steps: {
                  "3": "3",
                  "4": "4",
                  "5": "5",
                },
              },
              zeroCarbonStepCode: {
                heading: "Zero Carbon Step Code",
                stepRequired: "Level required",
                stepProposed: "Proposed step achieved",
                steps: {
                  "1": "EL 1",
                  "2": "EL 2",
                  "3": "EL 3",
                  "4": "EL 4",
                },
              },
              planInfo: {
                title: "Based on info provided by the builder & the following drawings:",
                author: "Plan author",
                version: "Plan version",
                date: "Plan date",
              },
            },
            completedBy: {
              heading: "C: Completed By",
              description:
                "Energy advisors working in teams may designate a contact person for this permit.  This person may or may not be the modeler.  The registration numbers must match the actual modelers registration.",
              energyAdvisor: "Energy Advisor",
              name: "Full name",
              date: "Date",
              company: "Company name",
              organization: "Service organization",
              phone: "Phone",
              energyAdvisorId: "Energy advisor ID#",
              address: "Address",
              email: "Email",
              codeco: "CODECO placed in Field 8 of H2K",
              yes: "Yes",
              no: "No",
              pFile: "P File #",
            },
            buildingCharacteristicsSummary: {
              heading: "D: Building Characteristics Summary",
              details: "Details (assembly / system type / fuel type / etc.)",
              addLine: "Add line",
              averageRSI: "Average effective RSI",
              performanceValues: "Performance values",
              roofCeilings: "Roof / Ceilings",
              aboveGradeWalls: "Above grade walls",
              framings: "Rim Joists / floor headers and lintels",
              unheatedFloors: "Floors over unheated space",
              belowGradeWalls: "Walls below grade",
              slabs: "Slabs",
              windowsGlazedDoors: "Windows and glazed doors:",
              usi: "USI",
              u_imp: "U-Imp",
              shgc: "SHGC",
              doors: "Doors:",
              rsi: "RSI",
              airtightness: "Air barrier system & location:",
              ach: "ACH",
              nla: "NLA",
              nlr: "NLR",
              spaceHeatingCooling: "Space heating / cooling:",
              principal: "Principal:",
              secondary: "Secondary:",
              afue: "AFUE",
              hspf: "HSPF",
              sse: "SSE",
              cop: "COP",
              seer: "SEER",
              hotWater: "Domestic hot water:",
              percent_eff: "% EFF",
              uef: "UEF",
              ef: "EF",
              eer: "EER",
              ventilation: "Ventilation",
              litersPerSec: "L/s",
              other: "Other",
              fossilFuels: {
                label: "Fossil fuels",
                no: "The building including all units is designed with NO fossil fuel use or infrastructure",
                yes: "The building IS designed to use fossil fuels or has infrastructure for it",
                unknown: "Fossil fuel use and infrastructure is unknown at this time",
              },
            },
            energyPerformanceCompliance: {
              heading: "E: Energy Performance Compliance",
              proposedHouseEnergyConsumption: "Proposed house energy consumption:",
              referenceHouseRatedEnergyTarget: "Reference house rated energy target:",
              energyUnit: "GJ/year",
              hvac: "HVAC",
              dwhHeating: "DWH Heating",
              sum: "SUM",
              calculationAirtightness:
                "The airtightness value used in the energy model calculations for the Proposed house is:",
              calculationTestingTarget: "OR Testing Target",
              compliance: "The above calculation was performed in compliance with Subsection 9.36.5. of Division B",
              airtightnessValue: {
                select: "Select",
                options: {
                  two_point_five: "2.5 ACH",
                  three_point_two: "3.2 ACH",
                },
              },
              epcTestingTargetType: {
                select: "Select",
                options: {
                  ach: "ACH@50Pa",
                  nla: "NLA@10Pa",
                  nlr: "NLR L/s/m2",
                },
              },
            },
            energyStepCodeCompliance: {
              heading: "F: 9.36.6 Energy Step Code Compliance",
              proposedConsumption: "Proposed House Rated Energy Consumption",
              refConsumption: "Reference House Rated Energy Target",
              consumptionUnit: "GJ/year",
              proposedMetrics: "Proposed House Metrics",
              requirement: "Proposed Step Requirement",
              results: "Proposed House Results",
              passFail: "Proposed House Pass or Fail",
              step: "Step Code Level",
              meui: "Mechanical Energy Use Intensity (MEUI)",
              meuiUnits: {
                numerator: "kWh",
                denominator: "m²·yr",
              },
              max: "(max)",
              min: "(min)",
              meuiImprovement: "% Improvement",
              tedi: "Thermal Energy Demand Intensity (TEDI)",
              tediUnits: {
                numerator: "kWh",
                denominator: "m²·yr",
              },
              hlr: "% Heat Loss Reduction",
              ach: "Airtightness in Air Changes per Hour at 50 Pa differential",
              achUnits: {
                numerator: "ACH",
                denominator: "@50Pa",
              },
              nla: "Normalized Leakage Area (NLA₁₀)",
              nlaUnits: {
                numerator: "10 Pa",
                denominator: "cm²/m²",
              },
              nlr: "Normalized Leakage Rate (NLR₅₀)",
              nlrUnits: "L/s/m²",
              requirementsMet: "Step Code requirements met:",
              otherData: {
                header: "Other data",
                software: "Software used",
                softwareVersion: "Software version",
                heatedFloorArea: "Heated floor area",
                volume: "Building volume",
                surfaceArea: "Building surface area",
                fwdr: "FWDR",
                climateLocation: "Climate data (location)",
                hdd: "Degree Days Below 18°C (HDD)",
                spaceCooled: "% Of Space Cooled",
              },
            },
            zeroCarbonStepCodeCompliance: {
              heading: "G: Zero Carbon Step Code Compliance",
              proposedMetrics: "Proposed House Metrics",
              stepRequirement: "Proposed Step Requirement",
              result: "Proposed House Result",
              passFail: "Proposed House Pass or Fail",
              step: "Zero Carbon Step Code Level",
              max: "Max",
              min: "Min",
              ghg: {
                label: "Total GHG",
                units: {
                  numerator: "kg CO₂ₑ",
                  denominator: "yr",
                },
              },
              co2: {
                title: "CO₂ₑ per floor area with max:",
                perFloorArea: {
                  label: "CO₂ₑ per floor area",
                  units: {
                    numerator: "kg CO₂ₑ",
                    denominator: "m²·yr",
                  },
                },
                max: {
                  label: "CO₂ₑ max",
                  units: "kg CO₂ₑ",
                },
              },
              prescriptive: {
                title: "Prescriptive:",
                heating: "Heating",
                hotWater: "Hot water",
                other: "All building systems, equipment, and appliances",
                zero_carbon: "zero carbon",
                carbon: "carbon",
              },
              requirementsMet: "Target reached:",
            },
            complianceGrid: {
              requirementsMetTag: {
                pass: "Pass",
                fail: "Fail",
              },
            },
          },
        },
        home: {
          jurisdictionsTitle: "Jurisdictions",
          siteConfigurationTitle: "Configuration management",
          jurisdictionsDescription:
            "Administer Review Managers and their roles within local jurisdictions through the Building Permit Hub. This includes inviting or removing managers, managing overall jurisdictions, customizing community pages, and handling jurisdiction-specific settings.",
          permitTemplateCatalogueTitle: "Permit templates catalogue",
          reportingTitle: "Reporting",
          reportingDescription:
            "Explore reports and analytics to gain insights and make informed decisions about your permit applications",
          permitTemplateCatalogueDescription:
            "Develop and publish a collection of permit templates that provide a standardized foundation for building permits across local jurisdictions. These templates include requirement blocks to establish a structured flow for the building permit template.",
          requirementsLibraryTitle: "Requirements library",
          requirementsLibraryDescription:
            "Construct and maintain requirement blocks that form the core structure of permit templates. This library allows you to create, update, and manage the questions that define each requirement block.",
          earlyAccess: {
            title: "Early Access",
            adminDescription:
              "Access and manage Early access previews and requirement sets before they become publicly available.",
            previews: {
              title: "Early access previews",
              description:
                "View and manage non-submittable permit templates in Early Access, shared with selected users for service design purposes.",
            },
            requirements: {
              title: "Early access requirements",
              description:
                "Explore and manage pre-release requirement blocks sets designed for testing within Early Access projects.",
            },
          },
          configurationManagement: {
            title: "Configuration management",
            reviewManagerDescription:
              "Configure your jurisdiction's operational setup within the Building Permit Hub. Set up your submission inbox, define energy step codes, and edit the 'About' page to reflect specific local information.",
            adminDescription:
              "Manage site-wide settings and messages along with providing administrative control to Super Admins.",
            jurisdictionLocalityTypeLabel: "Locality type of local jurisdiction",
            jurisdictionNameLabel: "Name of local jurisdiction",
            jurisdictionLocationLabel: "Location",
            jurisdictionAbout: {
              title: "My jurisdiction's About page",
              description:
                "Customize the informational page that submitters will see when they are in the Building Permit Hub.",
            },
            users: {
              title: "Users",
              description: "Manage and invite reviewers and other staff for this jurisdiciton",
            },
            submissionsInboxSetup: {
              title: "Submissions inbox setup",
              description: "Specify email addresses that should receive applications.",
              inboxEnabled: "Enable inbox",
              permitTypes: {
                label: "Send permit applications for",
                low_residential: "1-4 Unit detached housing",
                medium_residential: "4+ Unit housing",
                high_residential: "High density appartment buildings",
                new_construction: "New construction",
                addition_alteration_renovation: "Addition, alteration, or renovation",
                site_alteration: "Site alteration",
                demolition: "Demolition",
              },
              emailLabel: "to recipient email(s)",
              addEmail: "Add another email",
              confirmationRequired: "Action required: please click link in verification email",
            },
            stepCodeRequirements: {
              title: "Energy Step Code requirements",
              description: "Define step code requirements.",
              setMinimum:
                "Set the minimum acceptable levels of Energy Step Code and Zero Carbon Step Code for each permit type below:",
              part9Building: "Part 9 Building",
              addStep: "Add another requirement combination",
              deleteCustomization: "Delete customization",
              overriddenWarning: "This was overridden by your customized requirements below.",
              notRequired: "Not required",
              stepRequired: {
                permitTypeHeading: "PERMIT TYPE",
                standardToPass: "Standard Step Code compliance to pass",
                customizedMinimum: "Customized minimum requirement for submission",
                energy: {
                  title: "Energy Step Code Level",
                  options: {
                    "3": "3",
                    "4": "4",
                    "5": "5",
                  },
                },
                zeroCarbon: {
                  title: "Zero Carbon Step Code Level",
                  options: {
                    "1": "EL 1 - Measure Only",
                    "2": "EL 2 - Moderate",
                    "3": "EL 3 - Strong",
                    "4": "EL 4 - Zero Carbon",
                  },
                },
              },
            },
            externalApiKeys: {
              title: "API settings",
              description: "Manage API keys for the Building Permit Hub.",
            },
          },
          superAdminTitle: "Admin home",
          submissionsInboxTitle: "Submissions inbox",
          submissionsInboxDescription: "View all submitted building permit applications.",
          permitsTitle: "Digital building permits",
          permitsDescription:
            "Enhance building permits application process within your local jurisdiction by setting up helpful tips for submitters and selecting elective questions based on your local needs.",
          userManagementTitle: "User management",
          userManagementDescription: "Invite or remove Review Managers or Reviewers in the Building Permit Hub.",
          auditLogTitle: "Audit log",
          stepCodes: "Step Codes",
        },
        admin: {},
        errors: {
          fetchJurisdiction: "Something went wrong fetching the jurisdiction",
          fetchPermitApplication: "Something went wrong fetching the permit application",
          fetchPermitTypeOptions: "Something went wrong fetching the permit type options",
          fetchAutoComplianceModuleConfigurations: "Something went wrong fetching the auto compliance module options",
          fetchActivityOptions: "Something went wrong fetching the activity options",
          workTypeNotFound: "Work type not found",
          fetchWorkTypeOptions: "Something went wrong fetching the work type options",
          fetchRequirementTemplate: "Something went wrong fetching the requirement template",
          fetchTemplateVersion: "Something went wrong fetching the template version",
          fetchCurrentUserLicenseAgreements: "Please confirm your account to see license agreement",
          fetchTemplateVersions: "Something went wrong fetching template versions",
          fetchBuildingPermits: "Something went wrong fetching building permits",
          fetchBuildingPermit: "Something went wrong fetching building permit",
          fetchBuildingPermitJurisdictionChanges: "Something went wrong fetching building permit jurisdiction changes",
          fetchOptions: "Something went wrong fetching options",
          fetchJurisdictionTemplateVersionCustomization:
            "Something went wrong fetching jurisdiction template version customization",
          fetchIntegrationMapping: "Something went wrong fetching jurisdiction integration requirements mapping",
        },
        user: {
          fields: {
            role: "Role",
            email: "Email",
            name: "Name",
            createdAt: "Date added",
            lastSignInAt: "Last sign in",
          },
          index: {
            tableHeading: "User accounts",
            inviteButton: "Invite users",
          },
          changeRole: "Change role",
          newEmail: "New notification email address",
          changeEmail: "Change email",
          deleteAccount: "To delete your account, please contact <1>digital.codes.permits@gov.bc.ca</1>.",
          addUser: "Add more emails",
          invite: "Invite",
          invitedBy: "<strong>{{email}}</strong> has invited you to join:",
          invitedAsAdmin:
            "<strong>{{email}}</strong> has invited you to join BC Building Permit Hub as an administrator",
          invitedAs: "as a",
          invitationIntent:
            "This invitation is intended for <strong>{{email}}</strong>, if this is incorrect please contact the sender above.",
          invalidInvitationToken: {
            title: "Invalid invite",
            message: "Please contact your jurisdiction to request a new invitation link.",
          },
          createAccount: "Proceed with your account creation",
          omniauthProviders: {
            idir: "IDIR",
            bceidbasic: "Basic BCeID",
            bceidbusiness: "Business BCeID",
            bcsc: "BC Services Card Account",
          },
          changeBceid: "If you want to change your BCeID information, please go to ",
          changeBceidLinkText: "bceid.ca",
          confirmationRequiredWithEmail:
            "Action required: please click the link in the verification email that was sent to you. You will continue to receive emails at <strong>{{email}}</strong> until your new email is confirmed. <br/><br/>(Didn’t receive it? <1>Resend email</1>)",
          confirmationRequired:
            "Action required: please click the link in the verification email that was sent to you. <br/><br/>(Didn't receive it? <1>Resend email</1>)",
          receiveNotifications: "Receive notifications",
          notificationsEmail: "Notification email address",
          firstName: "First name",
          lastName: "Last name",
          myProfile: "My profile",
          inviteTitle: "Invite users",
          adminInviteTitle: "Invite super admins",
          inviteSuccess: "Invite sent!",
          reinvite: "Re-invite",
          reinviteSuccess: "Invite re-sent!",
          inviteTakenError: "Email taken",
          inviteError: "Invite error",
          takenErrorTitle: "Some of these emails already belong to existing users",
          takenErrorDescription:
            "One or more of the requested users have an existing account. Please ask them to change their email on their current account. You can then re-invite them into your local jurisdiction.",
          sendInvites: "Send invites",
          acceptInvitation: "Accept invitation",
          acceptInstructions: "Enter your login and other user info below to finalize your account creation.",
          rolesAndPermissions: "User roles & permissions",
          inviteInstructions:
            "Enter the email addresses of whom you wish to invite below.  For details about permissions for each role, please see",
          notifications: {
            essential: "Essential communications (cannot disable)",
            event: "Event",
            enableNotification: "Enable notification",
            templateChanged: "Changes to permit requirements",
            templateCustomized: "Jurisdiction customizations to permit requirements",
            applicationSubmitted: "Application submitted to jurisdiction",
            applicationViewed: "Application viewed by jurisdiction",
            applicationRevisionsRequested: "Revisions requested by jurisdiction",
            collaboration: "Collaboration",
            integrationMapping: "API integration mapping",
            unmappedApiNotification: "Unmapped API Notification",
          },
          emailConfirmed: {
            heading: "Email confirmed!",
            description: "You will receive updates and notifications at the confirmed email address.",
          },
          inApp: "In-App",
          email: "Email",
          // Leave in snake case so we can use: t(`user.roles.${role}`)
          roles: {
            submitter: "submitter",
            regional_review_manager: "regional review manager",
            review_manager: "review manager",
            reviewer: "reviewer",
            super_admin: "super admin",
          },
          rolesExplanation: {
            submitter:
              "The Submitter is typically an external user, such as a contractor, homeowner, or architect," +
              " who initiates the building permit application process. They are responsible for providing all necessary documentation and information required for the permit application. Submitters need to ensure their submissions are complete, accurate, and comply with local regulations.",
            review_manager:
              "The Review Manager supervises the Reviewers and the entire building permit review operation. In addition to possessing all the permissions of a Reviewer, Review Managers are tasked with administrative oversight. Their responsibilities include the distribution of work among Reviewers, maintaining efficiency and consistency in the review processes, and ensuring that the quality of service meets established standards. Moreover, Review Managers have extended privileges to modify local government-specific configurations within the building permit application system, such as updating Step Code requirements, managing content on the 'About' page, and other application settings pertinent to their jurisdictional needs.",
            reviewer:
              "A Reviewer is typically an employee within the local government or a designated authority responsible for examining building permit applications submitted by the Submitter. Reviewers assess the documentation for compliance with building codes, zoning laws, and other regulatory requirements. They may request additional information, approve, reject, or provide comments on the applications.",
            super_admin:
              "The Super Admin is the highest-level user within the system, with overarching control over the entire permit application platform. They have the authority to manage user roles, including creating and removing user accounts, and to modify the system configuration. This role is responsible for the maintenance of the system, including updates and enhancements, and ensuring that the system meets the operational and strategic objectives of the local government or the organization.",
          },
          assignTo: "Assign to...",
          department: "Department",
        },
        earlyAccessRequirementTemplate: {
          show: {},
          index: {
            tableHeading: "Previews",
            title: "Early access templates catalogue",
            invitationInfo:
              "Early access previews are non-submittable and accessible only by registered users who are invited. Access is granted for 60 days and can be extended or revoked at any time.",
            createButton: "Create new early access template",
            seeArchivedButton: "See archived",
            sharePreviewLink: "Share ({{ n }})",
            sharePreviewTitle: "Share preview",
            inviteToPreviewTitle: "Invite to preview",
            inviteToPreviewHint: "Separate each email with a comma ,",
            revokeButton: "Revoke",
            unrevokeButton: "Unevoke",
            extendButton: "Extend",
            inviteToPreviewButton: "Send invites",
            noPreviewersYet: "No previewers yet. Click invite to add previewers to this template",
            inviteToPreviewPartialSuccess: "Some invites failed to send",

            confirmation: {
              revokeTitle: "Are you sure you want to revoke access for {{ name }}?",
              revokeBody:
                "Revoking access will immediately prevent this user from accessing the early access content. This may be undone.",
              extendTitle: "Extend Access Duration for {{ name }}",
              extendBody:
                "Extending access will give the user 60 additional days to interact with the early access content. Do you want to proceed?",
              unrevokeTitle: "Restore Access for {{ name }}",
              unrevokeBody:
                "Restoring access will allow the user to access the early access content again. Are you sure you want to proceed?",
            },
          },
          new: {
            title: "Create new preview",
            modalTitle: "Create new preview",
            startingFresh: "Starting fresh?",
            addFromExisitng: "Add requirements from an exisitng permit?",
            startWithBlank: "Start with blank permit",
            copyFromLive: "Copy from live permit",
            copyFromThis: "Copy from this",
          },
          edit: {
            lastFetched: "Last fetched",
            fetchLatest: "Fetch latest",
            auditLog: "Audit log",
            confirmRemoveModalTitle: "Archive preview?",
            confirmRemoveModalBody: "This preview will no longer be accessible by invitees",
            public: "Grant public access?",
          },
          fields: {
            nickname: "Nickname",
            permitType: "Permit type",
            activity: "Work type",
            firstNations: "First nations?",
            sharedWith: "Shared with",
            updatedAt: "Updated at",
            assignee: "Assigned",
          },
        },
        requirementTemplate: {
          compareAction: 'Requirement "{{ requirementName }}" has been {{ action }}',
          changed: "changed",
          added: "added",
          removed: "removed",
          edit: {
            requirementsLibraryTab: "Requirements Library",
            earlyAccessRequirementsLibraryTab: "Early Access Requirements Library",
            earlyAccessTabDescription: "Early access previews cannot add 'Preview omitted' blocks ",
            options: {
              button: "Options",
              copyTips: "Import tips from ({{ templateLabel }})",
              copyElectives: "Import electives from ({{ templateLabel }})",
            },
            promoteElectives: "Export changes",
            promoteElectivesMessage:
              "This will publish your sandboxed customizations and overwrite your non-sandboxed live electives!",
            clickToWriteDescription: "Click to write description",
            title: "Permit Application Builder",
            dndTitle: "Drag to reorder",
            dndInstructions:
              "Change the order of requirements by using the menu on the left to drag items up or down. Click the 'Done' button to return to the form.",
            addSectionButton: "Add section",
            addRequirementButton: "Add requirement",
            saveDraft: "Save as draft",
            closeEditor: "Close editor",
            sectionsSidebarTitle: "Contents",
            reorderButton: "Reorder",
            removeConfirmationModal: {
              title: "Are you sure you want to remove this section?",
              body: "Any requirements inside this section will also be removed along with it.",
            },
            emptyTemplateSectionText: "Start by clicking the Add Section button",
            stepCodeWarnings: {
              energyStepCodeRecommended:
                'Warning:"Design package energy step code file" is present in the template, but there is no "Energy step code" requirement.',

              duplicateStepCodePackage:
                'Warning: Multiple "Design package energy step code files" found. Please ensure there is only one "Design package energy step code file".',
            },
            stepCodeErrors: {
              duplicateEnergyStepCode:
                'Warning: Multiple "Energy step code" requirements found. Please ensure there is only one "Energy step code" in the template.',
              stepCodePackageRequired:
                'Warning: "Energy step code" is required to have the "Design package energy step code file".',
              duplicateStepCodePackage:
                'Multiple "Design package energy step code files" found. Please ensure there is only one "Design package energy step code file" when there is an "Energy step code" requirement',
            },
            goToTop: "Go to top",
            collapseAll: "Collapse all",
            scheduleModalTitle: "Publish permit?",
            scheduleModalBody:
              "Once you publish, local jurisdictions and submitters will be able to see and use this new version of the form.",
            scheduleModalHelperText: "Schedule to <1>publish</1> (at midnight 00:01 PST)",
            scheduleModalCancelMessage: "Changes were not scheduled.",
            forcePublishNow: "Force publish!",
            pleaseReview: "Please review the following:",
            errorsBox: {
              title: "There are {{count}} fields with errors on the page",
              instructions: "Please fix the following before submitting:",
            },
            diffBox: {
              title: "Template changes",
              instructions: "Please review the following:",
              added: "Added",
              changed: "Changed",
              removed: "Removed",
              updateToNewVersion: "See new",
            },
            duplicateRequirementBlockDisabledReason: "This requirement block is already in the template",
            goToLatest: "Go to latest",
          },
          fields: {
            status: "Status",
            permitType: "Permit type",
            activity: "Work type",
            firstNations: "First Nations",
            description: "Description",
            currentVersion: "Current version",
            jurisdictionsSize: "Used by",
          },
          status: {
            published: "Published",
            scheduled: "Scheduled",
            draft: "Draft",
            deprecated: "Deprecated",
          },

          index: {
            tableHeading: "Templates",
            title: "Permit templates catalogue",
            description:
              "List of all permit templates in the system that's been created by the Super Admin. Only published templates will be visible to jurisdictions and submitters.",
            createButton: "Create new template",
            seeArchivedButton: "See archived",
          },
          new: {
            title: "Create new template",
            typePrompt: "What kind of building permit is this?",
            descriptionHelpText:
              "Provide some context for review managers and administrators on what kinds of buildings this permit is meant for.",
            createButton: "Create template",
            firstNationsLand: "This permit is intended only for <1>First Nation Registered Land</1>",
            copyExistingByClassifications: "Copy from existing template of this permit and work type if available",
          },
          versionSidebar: {
            triggerButton: "Versions",
            title: "Template versions",
            subtitlePrefix: "For:",
            viewTemplateButton: "View template",
            resumeDraftButton: "Resume draft",
            unscheduleButton: "Unschedule",
            listTitles: {
              published: "Published",
              draft: "Drafts",
              scheduled: "Scheduled",
              deprecated: "Deprecated (last 3)",
            },
            unscheduleWarning: {
              title: "Are you sure you want to unschedule this template?",
              body: "This action cannot be undone.",
            },
            deprecationReasonLabels: {
              unscheduled: "reason: unscheduled",
              new_publish: "reason: new publish",
            },
            lastUpdated: "Last updated",
          },
          export: {
            title: "Export Templates",
            downloadSummaryCsv: "Download Summary CSV",
            templateSummaryFilename: "Template Summary",
            downloadCustomizationCsv: "Download Customizations CSV",
            downloadCustomizationJson: "Download Customizations JSON",
          },
        },
        apiMappingsSetup: {
          title: "API mappings setup",
          index: {
            helperSubtitle: "See details of how fields are mapped to the API.",
            seeButton: "See {{status}}",
          },
          edit: {
            permitTemplate: "Permit template",
            seeApiDoc: "See API documentation",
            heading: "<1>Manage mapping for</1> <2>{{permitClassification}}</2>",
            table: {
              blockAccordionButton: "{{blockName}} <1>requirement block code: {{blockCode}}</1>",
              filter: {
                showAll: "Show all",
                showOnlyUnmapped: "Show only unmapped",
              },
              title: "API mappings",
              headers: {
                localField: "Your local field name",
                localFieldInfo: "Local field mapping info",
                templateField: "Map to <1>requirement code</1> in template",
                requirementDetail: "Field in template",
              },
              localFieldEdit: {
                addMapping: "Add local mapping",
                addMappingLabel: "Local mapping",
              },
            },
          },
        },
        digitalBuildingPermits: {
          index: {
            title: "Digital Building Permits",
            selectPermit: "Select a digital permit:",
            workType: "Work type",
            manageButton: "Manage",
            lastUpdated: "Last updated",
            requestNewPromptWithLink:
              "Your administrator has made the above permit types available for digital submissions. If there is another permit type you want please <1>request a new permit type</1>.",
            emptyPermitsText:
              "No available building permits of the selected work type. Please wait for updates from the Ministry of Housing.",
          },
          edit: {
            requirementBlockSidebar: {
              description:
                "Local jurisdictions can change building permit applications to fit their needs by adding elective fields and offering submitters practical tips. This helps make the application forms reflect the distinct regulations, standards, and requirements of each jurisdiction, so applicants provide the correct information needed by their area.",
              tipLabel: "Tip for submitters (optional)",
              filterLabel: "Search electives",
              sortLabel: "Sort by",
              filterPlaceholder: "Search electives",
              sortOptions: {
                labelAsc: "Elective (A-Z)",
                labelDesc: "Elective (Z-A)",
                reasonAsc: "Reason (A-Z)",
                reasonDesc: "Reason (Z-A)",
              },
              manageFieldsButton: "Manage elective field(s)",
              resetToDefaults: "Reset to defaults",
              selectFieldsTitle: "Select elective fields",
              electiveFormFields: "Elective form fields",
              addSelectedButton: "Add selected",
              reason: "Reason:",
              reasonLabels: {
                placeholder: "Select a reason",
                bylaw: "Bylaw",
                zoning: "Zoning",
                policy: "Policy",
              },
            },
          },
        },
        siteConfiguration: {
          title: "Site configuration management",
          adminUserIndex: {
            title: "Users",
            description: "View and manage administrative users",
          },
          landingPageSetup: {
            title: "Landing page setup",
            description: "Configure content for the landing page",
            selectOpenAccessPreviews:
              "Set open access previews to display on the front page. Select from the available public access previews below.",
            smallScale: "Set as Small Scale New Contruction Preview on landing page",
            fourPlus: "Set as Four Plus New Construction Preview",
          },
          sitewideMessage: {
            title: "Site-wide message",
            description: "Enable and configure a site-wide message",
            enable: "Show",
            label: "Site-wide message",
            hint: "This message will appear at the top of each page for all users.",
            settings: "Site-wide message settings",
          },
          revisionReasonsAttributesSetup: {
            title: "Revision reasons",
            description: "Setup reasons to be applied to revision requests",
            options: "Selectable options",
            fields: {
              reasonCode: "Reason code",
              reasonDescription: "Reason description",
              descriptionHint: "A short descriptor for the reason",
            },
          },
          helpDrawerSetup: {
            title: "Help drawer links",
            description: "Setup links to show in the help drawer for all users",
            settings: "Links",
            fields: {
              show: "Show",
              title: "Title",
              href: "CMS Lite GUID Link Url",
              description: "Description",
              titleHint: "Text for the main call-to-action",
              descriptionHint: "Short description below to give context",
            },
            getStartedLinkItem: {
              label: "Get started",
            },
            bestPracticesLinkItem: {
              label: "Best practices",
            },
            dictionaryLinkItem: {
              label: "Dictionary of terms",
            },
            userGuideLinkItem: {
              label: "User and role guide",
            },
          },
        },

        reporting: {
          title: "Reporting",
          tableHeading: "Available reports",
          filterPlaceholder: "Filter reports by name",
          stepCodeSummaryName: "Energy step code configuration by jurisdiction",
          stepCodeSummaryDescription: "Energy step code configuration by jurisdiction",
          templateSummary: {
            name: "Template summary",
            description: "A summary of template usage by jurisdictions",
            title: "Export Template Summary",
          },
          stepCodeSummary: {
            name: "Energy step code configuration by jurisdiction",
            description: "A summary of step code requirements by jurisdictions",
            title: "Export energy step code configuration by jurisdiction",
            filename: "Energy step code configuration by jurisdiction",
          },
          applicationMetrics: {
            name: "Basic Application metrics for all jurisdictions",
            description:
              "Submitted and draft application metrics by jurisdiction and type, excluding submissions created by employee accounts",
            title: "Basic Application metrics for all jurisdictions",
            filename: "Basic Application metrics for all jurisdictions",
          },
          columnHeaders: {
            name: "Name",
            description: "Description",
          },
        },
        externalApiKey: {
          index: {
            createExternalApiKey: "Create new API key",
            enabled: "Enabled",
            disabled: "Disabled",
            accessDocs: "Access the API Documentation",
            table: {
              heading: "API keys",
            },
            apiKeyInfo: {
              title: "Generate API Keys for Third-Party Integrators",
              body: "Use this screen to generate API keys for third-party integrations. For technical details on using the API, refer to our documentation site.<1>Access the API Documentation</1> Share the above link with developers or technical team members needing detailed integration information.",
            },
            disabledWarningTitle:
              "API keys for this jurisdiction have not been enabled. To enable them, please contact" + " us at",
            disabledTooltipLabel: "User is not authorized to make this change",
            disableConfirmationModal: {
              title: "Are you sure you want to disable API keys for this jurisdiction?",
              body: "All active API keys will be disabled",
            },
          },
          modal: {
            createTitle: "Create API key",
            manageTitle: "Manage API key",
            removeConfirmationModal: {
              title: "Are you sure you want to revoke this API key?",
              body: "Any applications using this token will be unable to access the API.",
            },
          },
          fieldLabels: {
            name: "Name",
            connectingApplication: "Application connecting to",
            revokedAt: "Revoked at",
            webhookUrl: "Webhook URL",
            expiredAt: "Expires on",
            createdAt: "Created at",
            token: "Token",
            status: "Status",
            notificationEmail: "Notification email",
            sandbox: "Sandbox",
          },
          notificationEmailHint:
            "This email will be used to notify your local integration partner about upcoming changes to API mappings. Note: Jurisdiction review managers will be notified via their registered email irrespective of this field",
          fieldPlaceholders: {
            webhookUrl: "https://example.com/webhook",
          },
          status: {
            active: "Active",
            notActive: "Not-Active",
          },
        },
        site: {
          title: "Building Permit Hub",
          titleLong: "Building Permit Hub",
          adminNavBarTitle: "Building Permit Hub - Admin Panel",
          adminPanel: "Admin Panel",
          beta: "Beta",
          linkHome: "Navigate home",
          didYouFind: "Did you find what you were looking for?",
          thankYouForResponse: "Thank you for your response!",
          actionRequired: {
            application_revisions_request:
              "You have received a request for revisions on application <1 />. Please revise before resubmitting (you won't lose your place in line).",
          },
          govFeedbackResponseNoReasons: {
            unclear: "This information is unclear",
            missingInfo: "This page is missing the information I need",
            notRelated: "This page is not related to what I searched for",
            other: "Other",
          },
          territorialAcknowledgement:
            "The B.C. Public Service acknowledges the territories of First Nations around B.C. and is grateful to carry out our work on these lands. We acknowledge thse rights, interests, priorities, and concerns of all Indigenous Peoples - First Nations, Métis, and Inuit - respecting and acknowledging their distinct cultures, histories, rights, laws, and governments.",
          home: "Home",
          contact: "Contact us",
          contactEmail: "digital.codes.permits@gov.bc.ca",
          contactInstructions_1: "If you have any questions or need assistance, see the contact options below:",
          contactInstructions_2: "Have a question about your digital permit application?",
          contactInstructions_3:
            "Please contact your local jurisdiction for questions related to your permit application.",
          contactTeamInstructionsTitle: "Contact the Building Permit Hub Team",
          contactTeamInstructions: [
            "Do you have some feedback for the Building Permit Hub Team?",
            "Do you have any questions or need assistance regarding your experience using the Building Permit Hub?",
          ],
          contactTeamCTA: "Please contact us at",
          contactNeedHelp: "Need general help?",
          contactNeedHelpInstructions:
            "Services are available in a variety of different languages and channels through Service BC.",
          contactNeedHelpCTA: "Get help with government services",
          listJurisdictions: "See list of jurisdictions",
          help: "Help",
          aboutTitle: "About",
          disclaimerTitle: "Disclaimer",
          copyrightHolder: "Government of British Columbia.",
          metaDescription:
            "The Building Permit Hub helps you submit a building permit application through a streamlined and standardized approach across jurisdictions in B.C. This tool connects you with local government and First Nation information to support the building permit submission process.",
          metaKeywords: "BC, british columba, permit, portal, hub, permitting, permit application",
          loggedInWelcome: "Welcome back!",
          myPermits: "My permits",
          newApplication: "New permit application",
          activePermits: "Active permits",
          approvedPermits: "Approved permits",
          myAccount: "My Account",
          giveFeedback: "Give feedback",
          error: "Something went wrong, please try refreshing the page",
          menu: "Menu",
          somethingWrong: "Something went wrong",
          pageNotFound: "Error, we can't find that page",
          pageNotFoundInstructions: "Please check that the web URL has been entered correctly.",
          pageNotFoundCTA: "Go back to home",
          pageNotFoundContactInstructions: "Do you believe this to be in error or are you stuck?",
          seeConsoleForDetails: "See the browser console for details",
          accessibility: "Accessibility",
          copyright: "Copyright",
          foippaWarning:
            "We are collecting your personal information for the purpose of creating and submitting a building permit application. We are collecting your personal information under section 26(c) of the Freedom of Information and Protection of Privacy Act. If you have questions about our collection of your information, please contact us at ",
          needMoreHelp: "Need more help?",
          pleaseContact: "Please contact your local government for questions related to your permit application.",
          forHelp: "For help with the Building Permit Hub please contact:",
          reviewNotifications: "Review notifications",
          breadcrumb: {
            latest: "Latest",
            profile: "Profile",
            jurisdictions: "Jurisdictions",
            new: "Create new",
            invite: "Invite",
            templateVersions: "Template versions",
            requirementsLibrary: "Requirements library",
            requirementTemplates: "Permit templates catalogue",
            edit: "Edit",
            users: "Users",
            editTemplate: "Edit template",
            editPermit: "Edit permit",
            permitApplications: "Permit applications",
            submissionInbox: "Submissions inbox",
            configuration: "Configure jurisdiction",
            sucessfulSubmission: "Application submitted",
            stepCodes: "Step Codes",
            digitalBuildingPermits: "Digital building permits",
            contact: "Contact us",
            configurationManagement: "Configuration management",
            energyStep: "Energy Step Code requirements",
            submissionsInboxSetup: "Submissions inbox setup",
            confirmed: "E-mail confirmed",
            welcome: "Welcome",
            sitewideMessage: "Site-Wide Message",
            apiSettings: "API settings",
            create: "Create",
            exportTemplates: "Export templates",
            reporting: "Reporting",
            exportTemplateSummary: "Export template summary",
            helpDrawerSetup: "Help drawer setup",
            revisionReasonsAttributesSetup: "Revision reason setup",
            apiMappings: "API mappings",
            manageMapping: "Manage mapping",
            revisionReasonSetup: "Revision reason setup",
            landingSetup: "Landing setup",
            acceptInvitation: "Accept invitation",
            eula: "End user license agreement",
            earlyAccess: "Early access",
            update: "Update",
          },
        },
        automatedCompliance: {
          baseMessage: `This field has Auto-Compliance capability`,
          defaultValueMessage: `Auto-Compliance found the default value to be "{{defaultValue}}".`,
          failedValueMessage: `Auto-Compliance was unable fill this field, please check with your local jurisdiction if this is required.`,
        },
      },
    },
    // ... other languages
  },
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  parseMissingKeyHandler: (key, defaultValue) => {
    return fallbackTranslations.en.fallback
  },
}

i18n.use(initReactI18next).init(options)

export type TTranslationResources = (typeof options)["resources"]

export default i18n
